<template>
    <div>
        <div class="flex flex-wrap">
            <div class="p-2 m-4 border border-solid" style="min-width: 120px" v-for="(item, index) in itemList" :key="index">
                <div class="mb-4 text-center">
                    <span>{{ $common.getLangDB(item) }}</span>
                </div>
                <div class="flex justify-between space-x-4" :class="{ 'text-primary cursor-pointer hover:font-semibold': hasFile(item.item_code, mtype.mtype_code) }" v-for="(mtype, index2) in item.mtype" :key="index2" @click="openPopup(item, mtype)">
                    <span>{{ $common.getLangDB(mtype) }}</span>
                    <span>{{ getValue(item.item_code, mtype) }}</span>
                </div>
            </div>
        </div>
        <vs-popup :title="popupTitle" fullscreen :active.sync="popupActive" @close="closePopup">
            <diagnosisForm :source_url="sourceUrl" v-if="popupData">
                <template slot="form">
                    <!-- 單選 -->
                    <div class="mb-6 text-xl flex justify-center flex-wrap space-x-4 whitespace-no-wrap" v-if="selectedMtype.form_type == 'radio'">
                        <vs-radio v-for="(selection, index) in selectedMtype.value_map_his" v-model.number="form[selectedMtype.mtype_code]" :vs-name="selectedMtype.mtype_code" :vs-value="index">{{ selection }}</vs-radio>
                    </div>
                </template>
                <template slot="btns">
                    <vs-button class="cd-buttom-2w rounded" @click="upsertMeasureData">確定</vs-button>
                    <vs-button class="cd-buttom-2w rounded" type="border" @click="closePopup">取消</vs-button>
                </template>
                <template slot="file">
                    <div v-if="popupData.imagefile && getImgType(selectedMtype.mtype_code) == 'iframe'">
                        <iframe :src="getImgUrl(selectedMtype.mtype_code) + popupData.imagefile" frameborder="0" :height="imgHeight" :width="imgWidth + 'px'" v-if="popupData.imagefile"></iframe>
                    </div>
                    <div v-if="popupData.imagefile && getImgType(selectedMtype.mtype_code) == 'img'">
                        <img :src="getImgUrl(selectedMtype.mtype_code) + popupData.imagefile" :width="imgWidth + 'px'" />
                    </div>
                    <div class="mb-6" :style="'width: ' + imgWidth + 'px'">
                        <el-table fit class="w-full" :data="selectedMtype.value_table_map.rows" v-if="popupData.value_table && selectedMtype.value_table_map">
                            <el-table-column width="70" fixed="left" show-overflow-tooltip>
                                <template v-slot="scope">
                                    <span class="text-black font-semibold">{{ scope.row }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label-class-name="text-black text-left" v-for="(col, index2) in selectedMtype.value_table_map.cols" :key="index2" :label="col" width="60" show-overflow-tooltip>
                                <template v-slot="scope">
                                    <div class="text-black text-left">
                                        <span>{{ popupData.value_table[scope.$index][index2] }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="mt-6" v-if="selectedMtype.mtype_id == 36">
                            <canvas ref="lineChart"></canvas>
                        </div>
                        <div class="mt-6" v-if="selectedMtype.mtype_id == 36">
                            <img src="@/assets/images/pages/audiogram.jpg" :width="imgWidth + 'px'" />
                        </div>
                    </div>
                </template>
            </diagnosisForm>
        </vs-popup>
    </div>
</template>
<script>
import { getEventItemList, getEventMeasureData, upsertMeasureData } from '@/api/user'
import diagnosisForm from '@/components/Module/diagnosisForm'
export default {
    components: {
        diagnosisForm,
    },
    data() {
        return {
            event: null,
            itemList: [],
            measureData: null,
            popupActive: false,
            popupTitle: '',
            popupData: null,
            sourceUrl: '',
            selectedItem: null,
            selectedMtype: null,
            form: [],
            chart: null,
            xAxisData: ['250(頻率)', '0.5K', '1K', '1.5K', '2K', '3K', '4K', '6K', '8K'],
        }
    },
    props: {
        event_id: {
            type: Number,
            required: true,
            default: 0,
        },
        pid: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    mounted() {
        this.getEventItemList()
        this.getEventMeasureData()
    },
    computed: {
        imgWidth() {
            const innerWidth = window.innerWidth
            const width = innerWidth > 630 ? innerWidth - 100 : innerWidth - 100
            return width
        },
        imgHeight() {
            const height = window.innerHeight
            return height
        },
        dname() {
            return this.$store.getters.name
        },
    },
    methods: {
        // 取義檢活動量測項目
        getEventItemList() {
            const payload = {
                event_id: this.event_id,
                withEventItemMt: 1,
            }
            getEventItemList(payload).then((res) => {
                this.event = res.data.data.data[0]
                this.itemList = this.event.item_list
            })
        },
        // 取義診活動所有會員的量測數據
        getEventMeasureData() {
            const payload = {
                event_id: this.event_id,
                pid: this.pid,
            }
            getEventMeasureData(payload).then((res) => {
                this.measureData = res.data.data.data[0]
            })
        },
        // 取得量測值
        getValue(item_code, mtype) {
            const mtype_code = mtype.mtype_code
            if (!this.measureData) return '-'
            if (!this.measureData[item_code]) return '-'
            if (!this.measureData[item_code][mtype_code]) return '-'

            const value = parseInt(this.measureData[item_code][mtype_code].value)

            // 如果是多選
            if (mtype.form_type == 'checkbox') {
                const values = this.$common.decimalToArray(value)
                if (mtype.value_map) {
                    const map = mtype.value_map
                    let output = ''
                    _.forEach(values, function (value) {
                        if (map[value]) output += map[value] + ','
                    })
                    return _.trim(output, ',')
                }
            }

            // 醫師填寫的
            if (mtype.value_map_his) {
                const map = mtype.value_map_his
                if (map[value]) return map[value]
            }

            // 義工填寫的
            if (mtype.value_map) {
                const map = mtype.value_map
                if (map[value]) return map[value]
            }

            return this.measureData[item_code][mtype_code].value
        },
        openPopup(item, mtype) {
            const item_code = item.item_code
            const mtype_code = mtype.mtype_code
            if (!this.hasFile(item_code, mtype_code)) return

            this.selectedItem = item
            this.selectedMtype = mtype
            this.popupData = this.measureData[item_code][mtype_code]
            if (this.popupData.source_url) this.sourceUrl = this.popupData.source_url
            this.form[mtype.mtype_code] = parseInt(this.popupData.value)
            if (this.selectedMtype.mtype_id == 36) this.showChart()
            this.popupTitle = this.$common.getLangDB(mtype)
            this.popupActive = true
        },
        closePopup() {
            this.popupActive = false
        },
        hasFile(item_code, mtype_code) {
            if (!this.measureData) return false
            if (!this.measureData[item_code]) return false
            if (!this.measureData[item_code][mtype_code]) return false

            if (this.measureData[item_code][mtype_code].imagefile) return true
            if (this.measureData[item_code][mtype_code].source_url) return true
            if (this.measureData[item_code][mtype_code].value_table) return true
            return false
        },
        getImgUrl(code) {
            const map = {
                ECG: 'electrocardiogram',
                Fundoscopy_OS: 'funduscopy',
                Fundoscopy_OD: 'funduscopy',
            }
            return map[code] ? process.env.VUE_APP_UPLOAD + '/' + map[code] + '/' : map[code]
        },
        getImgType(code) {
            const map = {
                ECG: 'iframe',
                Fundoscopy_OS: 'img',
                Fundoscopy_OD: 'img',
            }
            return map[code] ? map[code] : ''
        },
        // 寫入量測數據
        upsertMeasureData() {
            const _self = this
            let payload = { measures: [] }

            _.forEach(this.selectedItem.mtype, function (mtype) {
                if (_self.form[mtype.mtype_code] != null) {
                    payload.measures.push({
                        event_id: _self.event_id,
                        pid: _self.pid,
                        item_id: _self.selectedItem.item_id,
                        mtype_id: mtype.mtype_id,
                        value: Array.isArray(_self.form[mtype.mtype_code]) ? _self.$common.arrayToDecimal(_self.form[mtype.mtype_code]) : _self.form[mtype.mtype_code],
                        value_table: mtype.mtype_id == 36 ? _self.form['value_table'] : null, // 特規 純音聽力圖
                        writer: _self.dname,
                    })
                }
            })
            upsertMeasureData(payload).then((res) => {
                this.getEventMeasureData()
                this.closePopup()
            })
        },
        showChart() {
            const ctx = this.$refs.lineChart
            ctx.getContext('2d')
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: this.xAxisData,
                    padding: 1,
                    datasets: [
                        {
                            label: '左耳',
                            data: this.popupData.value_table[0],
                            borderColor: 'blue',
                            borderWidth: 1,
                            fill: false,
                        },
                        {
                            label: '右耳',
                            data: this.popupData.value_table[1],
                            borderColor: 'red',
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                },
                options: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    reverse: true,
                                    beginAtZero: true,
                                    min: -20,
                                    max: 120,
                                    stepSize: 10,
                                },
                            },
                        ],
                    },
                    plugins: {
                        title: function (tooltipItem) {
                            title = '頻率：' + tooltipItem[0].label
                            return title
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label = data.datasets[tooltipItem.datasetIndex].label || ''
                                if (label) {
                                    label = '分貝:' + tooltipItem.yLabel
                                }
                                return label
                            },
                        },
                    },
                },
            })
        },
    },
}
</script>
